<template>
  <button type="button" class="button button--square" @click="reverseOrder()">
    <img v-show="!value" class="icon" src="/icons/sort-ascending.svg" />
    <img v-show="value" class="icon" src="/icons/sort-descending.svg" />
  </button>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    reverseOrder() {
      this.$emit('update:value', !this.value)
    },
  },
}
</script>
