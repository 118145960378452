<template>
  <div v-if="totalPages > 1" class="c-pagination">
    <button type="button" class="button button--square" :disabled="page === 1" @click="onFirstPage">
      <img class="icon" src="/icons/chevrons-left.svg" />
    </button>
    <button type="button" class="button button--square" :disabled="page === 1" @click="onPreviousPage">
      <img class="icon" src="/icons/chevron-left.svg" />
    </button>
    <span class="page-info">{{ page }} / {{ totalPages }}</span>
    <button type="button" class="button button--square" :disabled="page === totalPages" @click="onNextPage">
      <img class="icon" src="/icons/chevron-right.svg" />
    </button>
    <button type="button" class="button button--square" :disabled="page === totalPages" @click="onLastPage">
      <img class="icon" src="/icons/chevrons-right.svg" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    data: {
      type: Array,
      required: true
    },
    pageSize: {
      type: Number,
      default: 20
    }
  },

  data() {
    return {
      page: 1,
    }
  },

  computed: {
    totalPages() {
      return Math.ceil(this.data.length / this.pageSize)
    }
  },

  watch: {
    data: {
      handler(newData) {
        if (newData.length > 0) {
          const pageData = newData.slice(0, this.pageSize)
          this.$emit('update:page', pageData)
        } else {
          this.page = 1
          this.$emit('update:page', [])
        }
      },
      immediate: true
    },
    totalPages: {
      // Reset current page if data filtered to less than 2 pages
      handler(newTotalPages) {
        if (newTotalPages < 2 && this.page > 1) {
          this.page = 1
        }
      },
      immediate: true
    }
  },

  methods: {
    onFirstPage() {
      this.page = 1
      const pageData = this.data.slice(0, this.pageSize)
      this.$emit('update:page', pageData)
    },
    onPreviousPage() {
      this.page -= 1
      const pageData = this.data.slice((this.page - 1) * this.pageSize, this.page * this.pageSize)
      this.$emit('update:page', pageData)
    },
    onNextPage() {
      this.page += 1
      const pageData = this.data.slice((this.page - 1) * this.pageSize, this.page * this.pageSize)
      this.$emit('update:page', pageData)
    },
    onLastPage() {
      this.page = this.totalPages
      const pageData = this.data.slice((this.page - 1) * this.pageSize, this.page * this.pageSize)
      this.$emit('update:page', pageData)
    }
  }
}
</script>

<style lang="scss" scoped>
.c-pagination {
  display: flex;
  align-items: center;

  .page-info {
    min-width: 4rem;
    text-align: center;
    padding-left: var(--spacing-s);
  }

  .button {
    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  .icon {
    width: 1rem;
    height: 1rem;
  }
}
</style>
