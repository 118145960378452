<i18n>
{
  "de": {
    "sortOptions": {
      "sort": "Sortieren"
    }
  }
}
</i18n>

<template>
  <div class="c-sort-dropdown-menu">
    <div>{{ $t('sortOptions.sort') }}:</div>

    <Menu :buttonText="selectedOptionText">
      <template #options>
        <MenuItem v-for="option in sortOptions" :id="`sort-option-${option.value}`" :key="option.value"
          :label="option.label" :type="'label'" :value="option.value" @click="updateSortOrder" />
      </template>
    </Menu>
  </div>
</template>

<script>
import Menu from '@/components/shared/menu/Menu.vue'
import MenuItem from '@/components/shared/menu/MenuItem.vue'

export default {
  components: {
    Menu,
    MenuItem,
  },

  props: {
    sortOptions: {
      type: Array,
      default: () => [],
    },
    selectedOption: {
      type: String,
      default: '',
    },
  },

  computed: {
    selectedOptionText() {
      return this.sortOptions.find((option) => option.value === this.selectedOption)?.label || '-'
    },
  },

  methods: {
    updateSortOrder(sortAttribute) {
      this.$emit('update:selectedOption', sortAttribute)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-sort-dropdown-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-xs);
}
</style>
